import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Soluzioni extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Prodotti"
          keywords={[`vision care`]}
          description="Soluzioni per lenti a contatto"
        />
        <h1 className="p-5 c">Soluzioni per lenti a contatto</h1>
      </Layout>
    )
  }
}

export default Soluzioni
